import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
  Optional,
  Self,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  FormControlName,
} from '@angular/forms';

@Component({
  selector: 'ui-input',
  templateUrl: './ui-input.component.html',
  styleUrls: ['./ui-input.component.scss'],
  encapsulation: ViewEncapsulation.None,

  providers: [
    // {
    //   provide: NG_VALUE_ACCESSOR,
    //   useExisting: forwardRef(() => UiInputComponent),
    //   multi: true,
    // },
  ],
})
export class UiInputComponent implements OnInit, ControlValueAccessor {
  // API
  @Input() nzModel!: any;
  @Input() nzPlaceHolder: string = '';
  @Input() nzSize: any = 'default';
  @Input() nzData!: any;
  @Input() nzCtrl!: string | AbstractControl;
  @Input() nzWrapClassName: string = '';
  @Input() nzClass!: string;
  @Input() nzType: string = 'text';
  @Input() nzPrefix: string | TemplateRef<void> = '';
  @Input() nzSuffix: string | TemplateRef<void> = '';
  @Input() nzPrefixSize!: string;
  @Input() nzPrefixIcon?: string;
  @Input()
  // set nzDisabled(value: boolean | '') {
  //   this._disabled = value === '' || value;
  // }
  @Input() nzBorderless = false;
  @Input() hasPlaceHolder = true;
  @Input() hasCancelBtn = false;
  @Input() minRowsNum: number;
  @Input() maxRowsNum: number;
  @Input() inputNumber: boolean;
  get nzDisabled(): boolean {
    return this._disabled;
  }
  @Input() isTextArea = false;

  // Variables
  hasPrefixAndSuffix: boolean = false;
  inputModel: any | undefined;
  _disabled = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.nzPrefix !== '' || this.nzSuffix !== '') {
      this.hasPrefixAndSuffix = true;
    }
  }

  // Auto binding
  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  // Define what should happen in this component, if something changes outside
  writeValue(input: any) {
    this.inputModel = input;
  }
}
