import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { NgZorroAntdModule, NZ_I18N, en_US } from "ng-zorro-antd";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { TranslateModule, TranslateLoader, MissingTranslationHandler, MissingTranslationHandlerParams } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as $ from 'jquery';
import { DataTablesModule } from "angular-datatables";
import { ShareModule } from "@ngx-share/core";
import { TemplateModule } from "./template/template.module";
import { GlobalDataStorage } from "./share/storages/global-data.storage";
import { PreLoginComponent } from "./layout/pre-login/pre-login.component";
import { Injector, APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { LocaleInterceptor } from "./routes/locale.interceptor";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  missingKeyObj = [];

  handle(params: MissingTranslationHandlerParams) {
    if (false) {
      return params.key;
    } else {
      if (this.missingKeyObj.indexOf(params.key) === -1) {
        this.missingKeyObj.push(params.key);
      }
      return `[MISSING-${params.key}]`;
    }
  }
}

@NgModule({
  declarations: [AppComponent, PreLoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ShareModule,
    TemplateModule,
    TranslateModule.forRoot({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
    GlobalDataStorage
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
