import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  // UntypedFormGroup,
  FormControlName,
  NgControl,
} from '@angular/forms';
// import { FlagUtils } from '@core/utils/flag-utils';
// import { findFlagUrlByCountryName } from 'country-flags-svg';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode';

@Component({
  selector: 'ui-select',
  templateUrl: './ui-select.component.html',
  styleUrls: ['./ui-select.component.scss'],
  encapsulation: ViewEncapsulation.None,

  providers: [
    // {
    //   provide: NG_VALUE_ACCESSOR,
    //   useExisting: forwardRef(() => UiSelectComponent),
    //   multi: true,
    // },
  ],
})
export class UiSelectComponent implements ControlValueAccessor {
  // API

  isReviewed = false;
  isTicked = false;

  @Input() nzPlaceHolder: string = '';
  @Input() nzAllowClear = true;
  @Input() nzShowSearch!: boolean;
  @Input() nzBorderless = false;
  @Input() nzSize: any = 'default';
  @Input() nzData!: any;
  @Input() nzCtrl!: string | AbstractControl;
  // @Input() nzForm!: UntypedFormGroup;
  @Input() nzClass!: string;
  @Input() nzOptions!: any;
  @Input() showCountryFlag: boolean = false;
  @Input() nzDropdownMatchSelectWidth: boolean = false;
  // @Input()
  // set nzDisabled(value: boolean | '') {
  //   this._disabled = value === '' || value;
  // }

  // @Input() isTagSelection = false;
  // get nzDisabled(): boolean {
  //   return this._disabled;
  // }

  @Input() arrowDownTemplate?: TemplateRef<{}>;
  @Input() formControlName?: string;
  @Input() isMatch?: boolean;

  @Input() nzReviewField = false;
  @Output() fieldReviewed = new EventEmitter<{
    formControlName: FormControlName;
    isCorrect: boolean;
  }>();

  @ViewChild('reviewField') reviewField: TemplateRef<any> | undefined;

  // Variables
  selectModel!: any;
  _disabled = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl,
    // private flagUtils: FlagUtils
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  // Auto binding
  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  // Define what should happen in this component, if something changes outside
  writeValue(input: string) {
    this.selectModel = input;
  }

  // ngAfterViewInit() {
  //   if (this.nzReviewField) {
  //     this.changeDetectorRef.detectChanges();
  //     if (this.isReviewed && this.isTicked) {
  //       let newClass = this.nzClass?.split(' ');

  //       if (newClass?.includes('review-incorrect')) {
  //         newClass.splice(newClass.indexOf('review-incorrect'), 1);
  //       }

  //       this.nzClass = newClass?.join(' ');

  //       this.nzClass += ' review-correct';
  //     } else if (this.isReviewed && !this.isTicked) {
  //       let newClass = this.nzClass?.split(' ');

  //       if (newClass?.includes('review-correct')) {
  //         newClass.splice(newClass.indexOf('review-correct'), 1);
  //       }

  //       this.nzClass = newClass?.join(' ');

  //       this.nzClass += ' review-incorrect';
  //     }
  //   }
  // }

  // getReviewResult(reviewInfo: any) {
  //   if (reviewInfo.isCorrect) {
  //     let newClass = this.nzClass?.split(' ');
  //     if (newClass?.includes('review-incorrect')) {
  //       newClass.splice(newClass.indexOf('review-incorrect'), 1);
  //     }
  //     this.nzClass = newClass?.join(' ');
  //     this.nzClass += ' review-correct';
  //   } else {
  //     let newClass = this.nzClass?.split(' ');
  //     if (newClass?.includes('review-correct')) {
  //       newClass.splice(newClass.indexOf('review-correct'), 1);
  //     }
  //     this.nzClass = newClass?.join(' ');
  //     this.nzClass += ' review-incorrect';
  //   }

  //   this.isReviewed = true;
  //   this.isTicked = reviewInfo.isCorrect ? true : false;

  //   const formControlName = this.ngControl as FormControlName;
  //   this.fieldReviewed.emit({
  //     formControlName: formControlName,
  //     isCorrect: reviewInfo.isCorrect,
  //   });
  // }

  // getFlag(country: string, isCode: boolean): string {
  //   if (isCode) {
  //     return getUnicodeFlagIcon(country);
  //   }

  //   return getUnicodeFlagIcon(this.flagUtils.getCountryFlagName(country)) + ' ';
  // }

  getLabelDisplay(
    isCountryCode: boolean,
    value: string,
    label: string
  ): string {
    if (isCountryCode) {
      return '+' + value;
    } else {
      return label;
    }
  }
}
