import { Component } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';

@Component({
    selector: "app-pre-login",
    templateUrl: "./pre-login.component.html"
})
export class PreLoginComponent {

    currentPage: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private globalDataStorage: GlobalDataStorage) {
        var lang = this.route.snapshot.queryParamMap.get('lang');
        if (lang) {
            if (lang == 'en') this.globalDataStorage.setStorage('locale', 'en');
            if (lang == 'ch') this.globalDataStorage.setStorage('locale', 'ch');
            if (lang == 'zh') this.globalDataStorage.setStorage('locale', 'zh');
            this.router.navigate([this.getUrl()]);
        }
    }

    onActivate(event) {
        window.scrollTo(0, 0);
        document.getElementById('pagefooter').hidden = false;
        if (this.getUrl() == '/about-ifast') this.currentPage = 'about';
        if (this.getUrl() == '/home') this.currentPage = 'home';
        if (this.getUrl() == '/banking-engine') this.currentPage = 'banking';
        if (this.getUrl() == '/wealth-solution') this.currentPage = 'wealth';
        if (this.getUrl() == '/white-labelled') this.currentPage = 'whiteLabelled';
        if (this.getUrl() == '/api-solution') this.currentPage = 'apiSolution';
        if (this.getUrl() == '/embedded-investment') this.currentPage = 'embeddedInvestment';
        if (this.getUrl() == '/robo-advisory') this.currentPage = 'roboAdvisory';
        if (this.getUrl() == '/bespoke-fintech-solution') this.currentPage = 'bespokeFintech';
        if (this.getUrl() == '/request-for-demo') this.currentPage = 'demo';
        if ((this.getUrl() == '/request-for-demo') || (this.getUrl() == '/news') || (this.getUrl().includes('/news-article'))) {
            document.getElementById('pagefooter').hidden = true;
        }
    }

    ngOnInit() {
        $(document).ready(function () {
            $('html, body').scrollTop(0);

            $(window).on('load', function () {
                setTimeout(function () {
                    $('html, body').scrollTop(0);
                }, 0);
            });
        });
    }

    getUrl() {
        var url = this.router.url;
        if (url.indexOf("#") != -1) {
            url = url.substr(0, url.indexOf("#"));
        }
        //assume ? is before #
        if (url.indexOf("?") != -1) {
            url = url.substr(0, url.indexOf("?"));
        }
        return url;
    }
}
