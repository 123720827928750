import {
  Component,
  ContentChild,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, 
} from '@angular/forms';
import { NzSelectComponent } from 'ng-zorro-antd/select';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UiInputDirective } from '../../../directives/ui-input.directive';

@Component({
  selector: 'select-placeholder',
  templateUrl: './select-placeholder.component.html',
  styleUrls: ['./select-placeholder.component.scss'],
})
export class SelectPlaceholderComponent {
  @ContentChild(NzSelectComponent) nzSelectComponent!: NzSelectComponent;

  @Input()
  set nzData(value: any) {
    this.hasValue = !!value;
  }

  @Input() nzCtrl!: string | AbstractControl;
  @Input() nzForm!: any;
  @Input() nzPlaceHolder: any;
  
  @ContentChild(UiInputDirective) input!: UiInputDirective;
  showFloatingLabel: boolean = false;
  focused$!: Observable<boolean>;

  private interaction: boolean = false;

  public hasValue: boolean = false;

  private subscription!: Subscription;
  private elementSubscription!: Subscription;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes['nzData']?.currentValue === "boolean"
    ) {
      this.hasValue = true;
    }
    if (changes['nzData'] && this.nzCtrl) {
      if (typeof this.nzCtrl === "string" && this.nzForm) {
        const formControl: AbstractControl | null = this.nzForm.get(
          this.nzCtrl
        );
        if (formControl) {
          if (this.subscription) {
            this.subscription.unsubscribe();
          }
          this.subscription = formControl.valueChanges
            .pipe(tap((val: any) => (this.hasValue = !!val)))
            .subscribe();
        }
      } else if (this.nzCtrl instanceof AbstractControl) {
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.subscription = this.nzCtrl.valueChanges
          .pipe(tap((val: any) => (this.hasValue = !!val)))
          .subscribe();
      }
    }
  }

  ngAfterContentInit(): void {
    if (this.input) {
      this.elementSubscription = this.input.showFloatedLabel$.subscribe(
        (show: boolean) => {
          this.interaction = show;
          this.showFloatingLabel = show;
        }
      );
      this.focused$ = this.input.focus$;
    }
    // else {
    //   console.warn('Please attach ui-input directive to the input field. The wrapper needs selector input [ui-input] to work.');
    // }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
