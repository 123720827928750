import {
  Component,
  ContentChild,
  Input,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UiInputDirective } from '../../../directives/ui-input.directive';

@Component({
  selector: 'input-placeholder',
  templateUrl: './floating-label.component.html',
  styleUrls: ['./floating-label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FloatingLabelComponent implements OnInit {
  // API
  // @Input()
  // set nzData(value: any) {
  //   this.showFloatingLabel = !!value || this.interaction;
  // }
  // @Input() nzCtrl!: string | AbstractControl;
  // @Input() nzForm!: FormGroup;

  // API
  @Input() nzData!: any;
  @Input() nzPlaceHolder!: string;
  @Input() nzWrapClassName: string = '';
  @Input() nzPrefixSize: string = '15';
  @Input() isNumberFormatter: boolean = false;
  @Input() isPrefixSize: boolean = false;

  @ContentChild(UiInputDirective) input!: UiInputDirective;

  showFloatingLabel: boolean = false;
  focused$!: Observable<boolean>;

  private interaction: boolean = false;
  private subscription!: Subscription;
  private elementSubscription!: Subscription;

  ngOnInit(): void {
    if (this.nzData) {
      this.showFloatingLabel = true;
    }

    if (this.isNumberFormatter) {
      this.showFloatingLabel = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.nzData ||
      this.nzData === 0 ||
      (changes['nzData']?.previousValue &&
        changes['nzData'].currentValue !== null &&
        this.focused$)
    ) {
      this.showFloatingLabel = true;
    } else {
      this.showFloatingLabel = false;
    }
  }

  ngAfterContentInit(): void {
    if (this.input) {
      this.elementSubscription = this.input.showFloatedLabel$.subscribe(
        (show: boolean) => {
          this.interaction = show;
          this.showFloatingLabel = show;
        }
      );
      this.focused$ = this.input.focus$;
    }
    // else {
    //   console.warn('Please attach ui-input directive to the input field. The wrapper needs selector input [ui-input] to work.');
    // }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.elementSubscription) {
      this.elementSubscription.unsubscribe();
    }
  }
}
