import { Component, OnInit } from '@angular/core';
import { GlobalDataStorage } from '../../storages/global-data.storage';
import { PopUpBannerService } from '../../services/pop-up-banner.service';

@Component({
  selector: 'pop-up-banner',
  templateUrl: './pop-up-banner.component.html',
  styleUrls: ['./pop-up-banner.component.scss']
})
export class PopUpBannerComponent implements OnInit {
  popUpBannerVisible: boolean = false;
  bannerLocale = this.globalDataStorage.get('locale') == 'en' ? 'En' : (this.globalDataStorage.get('locale') == 'ch' ? 'Ch' : 'Zh');
  popUpBannerPath = '/fintech/uploadMaterial/Fintech_Pop_Up_Banner_' + this.bannerLocale + '.png'
  popUpBannerRedirectionUrl: any;

  constructor(
    private globalDataStorage: GlobalDataStorage,
    private popUpBannerService: PopUpBannerService
  ) {}

  ngOnInit() {
    this.popUpBannerService.getPopUpBannerContent().subscribe(result => {
      if (result) {
        this.popUpBannerRedirectionUrl = result;
      } else {
        this.popUpBannerRedirectionUrl = null;
      }
    })
    if (this.globalDataStorage.displayPopUpBannerCompleted == false) {
      this.popUpBannerExist();
    }
  }

  popUpBannerExist(){
    var bannerImage = new Image();
    bannerImage.src = this.popUpBannerPath;
    bannerImage.onload = () => {
      this.popUpBannerVisible = true;
      this.globalDataStorage.displayPopUpBannerCompleted = true;
    }
  }

  handleCancel() {
    this.popUpBannerVisible = false;
  }
}
