import { Component, ContentChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { registerLocaleData } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import localeEn from '@angular/common/locales/en';
import localeCh from '@angular/common/locales/zh-Hant';
import localeZh from '@angular/common/locales/zh-Hans';

import { en_US, zh_TW, zh_CN, NzI18nService } from 'ng-zorro-antd';
registerLocaleData(localeCh, 'zh-tw');

@Component({
  selector: 'locale-selector',
  templateUrl: './locale-selector.component.html',
  styleUrls: ['./locale-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LocaleSelectorComponent {
  locale: string = 'en';
  languageHovered = false;
  isSelectPad = false;

  datatableLocale = {
    en: {
      sEmptyTable: 'No data available in table',
      sInfo: 'Showing _START_ to _END_ of _TOTAL_ entries',
      sInfoEmpty: 'Showing 0 to 0 of 0 entries',
      sInfoFiltered: '(filtered from _MAX_ total entries)',
      sInfoPostFix: '',
      sInfoThousands: ',',
      sLengthMenu: 'Show _MENU_ entries',
      sLoadingRecords: 'Loading...',
      sProcessing: 'Processing...',
      sSearch: 'Search:',
      sZeroRecords: 'No matching records found',
      oPaginate: {
        sFirst: 'First',
        sLast: 'Last',
        sNext: 'Next',
        sPrevious: 'Previous',
      },
      oAria: {
        sSortAscending: ': activate to sort column ascending',
        sSortDescending: ': activate to sort column descending',
      },
    },
    ch: {
      sProcessing: '處理中...',
      sLengthMenu: '顯示 _MENU_ 項結果',
      sZeroRecords: '沒有匹配結果',
      sInfo: '顯示第 _START_ 至 _END_ 項結果，共 _TOTAL_ 項',
      sInfoEmpty: '顯示第 0 至 0 項結果，共 0 項',
      sInfoFiltered: '(由 _MAX_ 項結果過濾)',
      sInfoPostFix: '',
      sSearch: '搜索:',
      sUrl: '',
      sEmptyTable: '表中數據為空',
      sLoadingRecords: '載入中...',
      sInfoThousands: ',',
      oPaginate: {
        sFirst: '首頁',
        sPrevious: '上頁',
        sNext: '下頁',
        sLast: '末頁',
      },
      oAria: {
        sSortAscending: ': 以升序排列此列',
        sSortDescending: ': 以降序排列此列',
      },
    },
    zh: {
      sProcessing: '处理中...',
      sLengthMenu: '显示 _MENU_ 项结果',
      sZeroRecords: '没有匹配结果',
      sInfo: '显示第 _START_ 至 _END_ 项结果，共 _TOTAL_ 项',
      sInfoEmpty: '显示第 0 至 0 项结果，共 0 项',
      sInfoFiltered: '(由 _MAX_ 项结果过滤)',
      sInfoPostFix: '',
      sSearch: '搜索:',
      sUrl: '',
      sEmptyTable: '表中数据为空',
      sLoadingRecords: '载入中...',
      sInfoThousands: ',',
      oPaginate: {
        sFirst: '首页',
        sPrevious: '上页',
        sNext: '下页',
        sLast: '末页',
      },
      oAria: {
        sSortAscending: ': 以升序排列此列',
        sSortDescending: ': 以降序排列此列',
      },
    },
  };

  constructor(
    private translate: TranslateService,
    private globalDataStorage: GlobalDataStorage,
    private i18n: NzI18nService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.locale = this.globalDataStorage.getStorage('locale') || 'en';
    this.toggleShow(false);
    $.extend($.fn.dataTable.defaults, {
      language: this.datatableLocale[this.locale],
    });
  }

  toggleShow(refresh: boolean) {
    console.log(this.locale);
    this.translate.use(this.locale);
    this.globalDataStorage.setStorage('locale', this.locale);
    if ('en' === this.locale) {
      registerLocaleData(localeEn);
      this.i18n.setLocale(en_US);
    } else if ('ch' === this.locale) {
      registerLocaleData(localeCh, 'zh-tw');
      this.i18n.setLocale(zh_TW);
    } else if ('zh' === this.locale) {
      registerLocaleData(localeZh, 'zh-cn');
      this.i18n.setLocale(zh_CN);
    }
    if (refresh) window.location.reload();
  }

  getUrl() {
    var url = this.router.url;
    if (url.indexOf('#') != -1) {
      url = url.substr(0, url.indexOf('#'));
    }
    //assume ? is before #
    if (url.indexOf('?') != -1) {
      url = url.substr(0, url.indexOf('?'));
    }
    return url;
  }

  select() {
    this.isSelectPad = !this.isSelectPad;
  }

  closeSelectPad() {
    this.isSelectPad = false;
  }

  selectLocale(localeValue: string) {
    console.log(localeValue);
    if (localeValue != this.locale) {
      this.locale = localeValue;
    }
    console.log(this.locale);
    this.translate.use(this.locale);
    this.globalDataStorage.setStorage('locale', this.locale);
    window.location.reload();
    if ('en' === this.locale) {
      registerLocaleData(localeEn);
      this.i18n.setLocale(en_US);
    } else if ('ch' === this.locale) {
      registerLocaleData(localeCh, 'zh-tw');
      this.i18n.setLocale(zh_TW);
    } else if ('zh' === this.locale) {
      registerLocaleData(localeZh, 'zh-cn');
      this.i18n.setLocale(zh_CN);
    }
  }
}
