import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';

@Injectable({
    providedIn: "root"
})

export class PopUpBannerService {

    locale: string;

    constructor(
        private http: HttpClient,
        private globalDataStorage: GlobalDataStorage
    ){
        this.locale = this.globalDataStorage.getStorage('locale');
    }

    getPopUpBannerContent(): Observable<any> {
        var URL = "/fintech/uploadMaterial/fintechBannerRedirectionUrl.html";
        return this.http.get(URL, { headers: { 'Cache-Control': ['no-cache', 'no-store']}, responseType: 'text' });
    }
}

