import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'more-solution-ready-section',
  templateUrl: './more-solution-ready-section.component.html',
  styleUrls: ['./more-solution-ready-section.component.scss']
})
export class MoreSolutionReadySectionComponent implements OnInit {
  @Input() page: string;

  constructor() { }

  ngOnInit(): void {
  }

}
