import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';
import { ContactUsService } from 'src/app/share/services/contact-us.service';
import { NzModalService } from 'ng-zorro-antd'
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router, Event } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FooterComponent {

    currentYear: any;
    locale: any;
    validateForm: FormGroup;
    loading: boolean;
    submitSuccess: boolean;
    emailType = 'contactUs';
    routeSubscription: any;
    isAboutUsPage: boolean = false;
    enquiryOptions = [
      {value: 'banking', label: 'header.banking.solutions'},
      {value: 'wealth', label: 'header.wealth.solutions'},
      {value: 'treasurynow', label: 'treasurynow.solution'}
    ]
    onlyDigitPattern : string = "^[0-9 ]*[0-9 ]";

    constructor(
        private globalDataStorage: GlobalDataStorage,
        private contactUsService: ContactUsService,
        private fb: FormBuilder,
        private modalService: NzModalService,
        private translateService: TranslateService,
        private router: Router
	) {
        this.locale = this.globalDataStorage.getStorage('locale') || 'en';
        this.routeSubscription = this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
              const url = this.router.url;
              if (url === "/about-ifast") {
                this.isAboutUsPage = true;
              } else {
                this.isAboutUsPage = false;
              }
            }
          });
	}

    ngOnInit() {
        this.currentYear = new Date().getFullYear();
        this.validateForm = this.fb.group({
            name: [null, [Validators.required]],
            email: [null, [Validators.email, Validators.required]],
            phone: [null, [Validators.pattern(this.onlyDigitPattern), Validators.required]],
            type: [null, [Validators.required]],
            message: [null, [Validators.required]]
        });
    }

    onActivate(event) {
        window.scrollTo(0, 0);
    }

    submitForm(): void {
        this.submitSuccess = false;
        for (const i in this.validateForm.controls) {
            this.validateForm.controls[i].markAsDirty();
            this.validateForm.controls[i].updateValueAndValidity();
        }
        if (this.validateForm.valid) {
            let name = this.validateForm.controls.name.value;
            let email = this.validateForm.controls.email.value;
            let phone = this.validateForm.controls.phone.value;
            let type = this.validateForm.controls.type.value;
            let message = this.validateForm.controls.message.value;

            this.loading = true;
            this.contactUsService.sendFintechRequestForDemoEmailByType(
              name,
              email,
              phone,
              message,
              this.emailType,
              type
            ).subscribe(result => {
                if (result.success) {
                    this.submitSuccess = true;
                    this.validateForm.reset();
                    this.modalService.success({
                        nzTitle: this.translateService.instant('enquiry.submitted')
                    });
                }
                this.loading = false;
            });
        }
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }
}
