import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { PreLoginComponent } from "./layout/pre-login/pre-login.component";
import { LAYOUT_ROUTES } from "./routes/routes";

const appRoutes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full"
  },
  {
    path: "",
    component: PreLoginComponent,
    data: { title: "content Views" },
    children: LAYOUT_ROUTES
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: "reload",
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
