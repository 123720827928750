import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { GlobalDataStorage } from "./share/storages/global-data.storage";
import { ResponsiveService } from './share/services/responsive.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'hk-b2b-fintech-web';

  constructor(
    translate: TranslateService,
    globalDataStorage: GlobalDataStorage,
    private responsiveService: ResponsiveService
  ) {
    translate.setDefaultLang(globalDataStorage.getStorage("locale") || "en");
    translate.use(globalDataStorage.getStorage("locale") || "en");
  }

  ngOnInit() {
    this.responsiveService.observe();
  }
}
