import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalDataStorage } from 'src/app/share/storages/global-data.storage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  @Input() currentPage: string;
  solutionsHovered = false;
  visibleMenu = false;
  locale: string;
  solutionDivider: boolean = true;
  isSolutionPad: boolean = false;
  //   visibleMobileDropdown = false;
  solutionList: any = [
    {
      key: 'white.labelled.solution',
      value: '/white-labelled',
      page: '/whiteLabelled',
    },
    {
      key: 'ifast.api.solution',
      value: '/api-solution',
      page: '/apiSolution',
    },
    {
      key: 'embedded.investment.tools',
      value: '/embedded-investment',
      page: '/embeddedInvestment',
    },
    {
      key: 'robo.advisory.solutions',
      value: '/robo-advisory',
      page: '/roboAdvisory',
    },
    {
      key: 'bespoken.fintech.solution',
      value: '/bespoke-fintech-solution',
      page: '/bespokeFintech',
    },
    {
      key: 'treasurynow.api.solution',
      value: '/treasurynow-api-solution',
      page: '/treasuryNowApiSolution',
    },
  ];

  constructor(
    private globalDataStorage: GlobalDataStorage,
    private translateService: TranslateService
  ) {
    this.locale = this.globalDataStorage.getStorage('locale');
  }

  ngOnInit() {}

  openMenu(): void {
    this.visibleMenu = true;
  }

  closeMenu(): void {
    this.visibleMenu = false;
    this.isSolutionPad = false;
  }

  onChangePage(): void {
    this.closeMenu();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  showSolutionPad() {
    this.isSolutionPad = !this.isSolutionPad;
  }
}
