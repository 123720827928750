import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgZorroAntdModule } from "ng-zorro-antd";
import { DataTablesModule } from "angular-datatables";
import { TranslateModule } from "@ngx-translate/core";
import { AnimateOnScrollDirective } from "./directives/animate-on-scroll.directive";
import { LocaleSelectorComponent } from './components/locale-selector/locale-selector.component';
import { MoreSolutionReadySectionComponent } from './components/more-solution-ready-section/more-solution-ready-section.component';
import { PopUpBannerComponent } from './components/pop-up-banner/pop-up-banner.component';
import { UiInputComponent } from './components/ui-input/ui-input.component';
import { FloatingLabelComponent } from './components/ui-input/floating-label/floating-label.component';
import { UiInputDirective } from './directives/ui-input.directive';
import { SelectPlaceholderComponent } from './components/ui-select/select-placeholder/select-placeholder.component';
import { UiSelectComponent } from './components/ui-select/ui-select.component';
@NgModule({
    imports: [CommonModule, RouterModule, NgZorroAntdModule, DataTablesModule, TranslateModule, FormsModule, ReactiveFormsModule],
    declarations: [
        AnimateOnScrollDirective,
        LocaleSelectorComponent,
        MoreSolutionReadySectionComponent,
        PopUpBannerComponent,
        UiInputComponent,
        FloatingLabelComponent,
        UiInputDirective,
        SelectPlaceholderComponent,
        UiSelectComponent,
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgZorroAntdModule,
        DataTablesModule,
        TranslateModule,
        ReactiveFormsModule,
        AnimateOnScrollDirective,
        LocaleSelectorComponent,
        MoreSolutionReadySectionComponent,
        PopUpBannerComponent,
        UiInputComponent,
        FloatingLabelComponent,
        SelectPlaceholderComponent,
        UiSelectComponent,
    ],
    providers: []
})
export class SharedModule {}
