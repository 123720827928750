import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class ContactUsService {
    constructor(private http: HttpClient) {}

    sendFintechRequestForDemoEmailByType(name:string, email:string, phone:string, message:string, emailType:string, type:string): Observable<any> {
        const URL = environment.serverUrl + "/rest/contact/us/send-fintech-request-for-demo-email-by-type";
        const options = { params: { name, email, phone, message, emailType, type} };
        return this.http.post(URL, null, options);
    }

    findMilestone() : Observable<any> {
      const URL = environment.serverUrl + "/rest/contact/us/about-us-milestone";
      const options = {};
      return this.http.post(URL, options);    
    }
}
