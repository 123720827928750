import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { MobileWebBreakpoint } from '../models/i-breakpoint.model';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService implements OnDestroy {
  private subscription: Subscription;
  private mobileView: boolean;
  mobileViewEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private bpObserver: BreakpointObserver
  ) {}

  isMobileView() {
		return this.mobileView;
	}

  observe() {
    this.subscription = this.bpObserver
      .observe(MobileWebBreakpoint)
      .pipe(
        map(({ breakpoints }) => breakpoints),
        map(breakpointMap => {
          return breakpointMap[MobileWebBreakpoint];
        }),
        distinctUntilChanged(),
        tap((mobileView: boolean) => {
          this.mobileView = mobileView;
          this.mobileViewEvent.emit(this.mobileView);
          window.scrollTo({
            top: 0,
            left: 0,
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
